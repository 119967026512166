import React from 'react'

export const WithBorder = ({ children, color = 'black', style = {} }) =>
  React.cloneElement(children, {
    style: { ...style, border: `1px solid ${color}` },
  })

export const WithBackgroud = ({ children, color = 'grey', style = {} }) =>
  React.cloneElement(children, {
    style: { ...style, backgroundColor: color },
  })
